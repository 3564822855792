import React from "react";
import Image from "next/image";
import Link from "next/link";
import { navbarData } from "./data/navbarData";

//Hooks

//Mui
import styled from '@mui/system/styled';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";

//Icons
import Logo from '~/assets/img/vortex-air-logo-250.png';
import LogoSolo from '~/assets/img/vortex-logo-solo.jpg'
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';

//Components
import NavbarButtonTabs from "./NavbarButtonTabs";
import NavbarMenu from "./nabarMenu/NavbarMenu";

export default function NavbarV2() {

    //Media Query
    const showPhone = useMediaQuery('(min-width:1300px)');
    const showEmployeeLogin = useMediaQuery('(min-width:1600px)');
    const showHeroTextSection = useMediaQuery('(min-width:1500px)');
    const isMobile = useMediaQuery('(max-width:1300px)');
    
    return(
        <StyledNavbar sx={{backgroundColor: "background.default"}}>
            <AppBar color="default">
                <StyledToolbar disableGutters>                         
                        <StyledToolbarItem>
                            {showHeroTextSection && 
                            <Link href="/" style={{textDecoration: "none"}}>
                                <StylesHeroSection>
                                    <Image src={LogoSolo.src} width={35} height={40} alt="logo" style={{marginRight: ".5rem"}}/>
                                    <StylesVortextText color="primary">vortex</StylesVortextText>
                                    <StylesAviationText color="primary">aviation</StylesAviationText>
                                </StylesHeroSection>
                            </Link>
                            }

                            {!showHeroTextSection && 
                                <StyledNavbarLogo href="/home">
                                    <Image alt="Vortex logo" {...Logo}/>
                                </StyledNavbarLogo>
                            }

                            {!isMobile && 
                                <StyledNavButtonsWrapper>
                                    <NavbarButtonTabs items={navbarData.items}/>
                                </StyledNavButtonsWrapper>
                            }
                        </StyledToolbarItem>


                        <StyledToolbarItem>

                            {showPhone && 
                                <Box>
                                    <StyledPhoneLink href="tel:787-629-8014">
                                        <LocalPhoneRoundedIcon fontSize="small" sx={{marginRight: ".5rem"}}/>
                                        <Typography variant="body1" color="inherit">787-629-8014</Typography>
                                    </StyledPhoneLink>
                                </Box>
                            }

                            {showEmployeeLogin && 
                                <Box sx={{marginLeft: "1rem"}}>
                                    <Link href="https://mbox.s501.sureserver.com/?domain=vortex-air.com&" style={{textDecoration: "none", color:"inherit"}}>
                                        <Button variant='outlined'>
                                            <Typography variant='body1'>EMPLOYEE LOGIN</Typography>
                                        </Button>
                                    </Link>
                                </Box>
                            }


                            {isMobile && 
                                <NavbarMenu />
                            }
                        </StyledToolbarItem>
                </StyledToolbar>
            </AppBar>
        </StyledNavbar>
    )
}

const StyledNavbar = styled(Box)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    zIndex: "1000",
    
    "@media (max-width: 900px)": {
        
      }
}))

const StyledToolbar = styled(Toolbar)(({theme}) => ({
    padding: ".5rem 1rem",
    display: "flex",
    justifyContent: "space-between",

    "@media (max-width: 1920px)": {
        width: "100%",
    }

}))

const StyledToolbarItem = styled(Box)(({theme}) => ({
    display: "flex",
    alignItems: "center"
}))


const StylesHeroSection = styled(Box)(({theme}) => ({
    display: "flex",
    alignItems: "center",
}))

const StylesHeroTextSection = styled(Box)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    "@media (max-width: 900px)": {
        flexDirection: "column",
    }
    
}))

const StylesVortextText = styled(Typography)(({theme}) => ({
    display: "flex",
    fontSize: "38px",
    fontWeight: "600",
    fontFamily: "Sea-Dog Expanded",
    lineHeight: "0",
    "@media (max-width: 900px)": {
        fontSize: "28px",
        lineHeight: "1.2rem",
    }
}))
const StylesAviationText = styled(Typography)(({theme}) => ({
    display: "flex",
    fontSize: "26px",
    fontWeight: "600",
    marginLeft: ".5rem",
    fontFamily: "Sea-Dog Expanded",
    lineHeight: "0",
    "@media (max-width: 900px)": {
        fontSize: "24px",
        lineHeight: "1.2rem",
        marginLeft: "0",

    }
}))

const StyledNavbarLogo = styled(Link)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    marginRight: ".5rem",
    "img": {
        display: "bloc",
        height: "40px",
        width: "auto",
        objectFit: "scale-down",
        borderRadius: "2px",
    }
}))

const StyledNavButtonsWrapper = styled(Box)(({theme}) => ({
    marginLeft: "1rem",
    "@media (max-width: 900px)": {
        display: "none",
    }
}))

const StyledPhoneLink = styled(Link)(({theme}) => ({
    textDecoration: "none", 
    color:"inherit", 
    display: "flex", 
    alignItems: "center",

    "&:hover": {
        color: theme.palette.primary.main,
        transition: "all ease .5s",
    }

}))