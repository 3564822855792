import React, {useEffect, useState} from 'react';
import { motion, useSpring, useAnimation } from "framer-motion";

//Mui
import styled from '@mui/system/styled';
import useTheme from '@mui/system/useTheme';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

interface FooterIconLinkProps{
    href: string;
    icon: React.ReactNode;
}


export default function FooterIconLink(props: FooterIconLinkProps) {
    const [hovered, setHovered] = useState(false);
    const controls = useAnimation();
    const theme = useTheme();

    useEffect(() => {

        if(hovered){
            controls.start('hovered');
        } else {
            controls.start('notHovered');
        }

    }, [hovered])
  return (
    <StyledLink
    href={props.href}
    onMouseEnter={() => setHovered(true)}
    onMouseLeave={() => setHovered(false)}
    animate={controls}
    transition={{ duration: .5}} 
    variants={{
        hovered: {
            backgroundColor: theme.palette.primary.main,
        },
        notHovered: {
            backgroundColor: theme.palette.grey[100],
        }
    }}
    >   
        <StyledIcon
        animate={controls}
        initial="notHovered"
        transition={{ duration: .5 }} 
        variants={{
            hovered: {
                color: theme.palette.grey[100],
            },
            notHovered: {
                color: theme.palette.primary.main,
            }
        }}    

        >
            {props.icon}
        </StyledIcon>

    </StyledLink>
  )
}

const StyledLink = styled(motion.a)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    textDecoration: "none",
    borderRadius: "100%",
    padding: ".5rem",
    border: `2px solid ${theme.palette.primary.main}`,

    ":hover": {
        cursor: "pointer"
    }
}));

const StyledIcon = styled(motion.div)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "28px",
    color: theme.palette.primary.main,
}));