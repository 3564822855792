import React from "react";
import Link from 'next/link';
import { navbarData } from "~/components/_core/navbar/data/navbarData";
//Hooks
import useNavbar from "~/hooks/redux/navbar/useNavbar";

//Mui
import styled from '@mui/system/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconButton from '@mui/material/IconButton';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';


//Icons
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import SmartphoneRoundedIcon from '@mui/icons-material/SmartphoneRounded';

//Components
import NavbarMenuSubItems from "./NavbarMenuSubItems";

export default function NavbarMenu() {
    const {navbarMenuVisibility, setNavbarMenuVisibility} = useNavbar();

    function handleItemClick(){
        setNavbarMenuVisibility(false);
    }
    
    return(
        <StyledNavbarMenuContainer>
            <StyledMenuIconButton onClick={() => setNavbarMenuVisibility(true)} size="small">
                <MenuIcon fontSize='inherit'/>
            </StyledMenuIconButton>

            <SwipeableDrawer
            anchor="left"
            open={navbarMenuVisibility}
            onClose={() => setNavbarMenuVisibility(false)}
            onOpen={() => setNavbarMenuVisibility(true)}
            >
                <StyledDrawerContainer>
                    <StyledDrawerContainerItem sx={{height: "90vh"}}>
                        <StyledList>
                            {navbarData.items.map((item, index) => (
                                <Box key={`nav-menu-sub-items-box-${index}`}>
                                {item.subItems
                                    ?   <NavbarMenuSubItems key={`nav-menu-sub-items-${index}`} subItems={item.subItems} label={item.label} index={index}/>
                                    :   <Link key={`nav-menu-item-${index}`}
                                        href={item.to}
                                        style={{color: "inherit", textDecoration: "none"}}
                                        >
                                            <StyledMenuItem onClick={handleItemClick}>
                                                <StyledMenuItemText>{item.label}</StyledMenuItemText>
                                            </StyledMenuItem>
                                        </Link>
                                }
                                </Box>
                            ))}
                            <Box sx={{margin: "1rem 0"}}>
                                <Divider/>     
                            </Box>
                            <Link href={"https://mbox.s501.sureserver.com/?domain=vortex-air.com&"}
                            style={{color: "inherit", textDecoration: "none"}}
                            >
                            <StyledMenuItem onClick={handleItemClick}>
                                <StyledMenuItemText>Employee Login</StyledMenuItemText>
                            </StyledMenuItem>
                            </Link>
                        </StyledList>
                    </StyledDrawerContainerItem>

                    <StyledDrawerContainerItem >
                        <StyledBottomLink
                        href={`tel:787-806-6017`}
                        >
                            <SmartphoneRoundedIcon fontSize="small"/>
                            <Typography variant="body1" marginLeft=".5rem">787-806-6017</Typography>
                        </StyledBottomLink>
                        <StyledBottomLink
                        href={`mailto:vortex.mx@usa.com`}
                        >
                            <EmailRoundedIcon fontSize="small"/>
                            <Typography variant="body1" marginLeft=".5rem">vortex.mx@usa.com</Typography>
                        </StyledBottomLink>
                    </StyledDrawerContainerItem>
                </StyledDrawerContainer>
            </SwipeableDrawer>
        </StyledNavbarMenuContainer>
    )
}

const StyledNavbarMenuContainer = styled(Box)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    padding: "0",
    margin: "0",
}))

const StyledMenuIconButton = styled(IconButton)(({theme})=>({
    fontSize: "26px",
    borderRadius: "0.4rem",
    border: `2px solid ${theme.palette.secondary.main}`
}))


const StyledDrawerContainer = styled(Box)(({theme})=>({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
}))

const StyledDrawerContainerItem = styled(Box)(({theme})=>({
    display: "flex",
    flexDirection: "column",
    padding: "1rem 0"
}))


const StyledList = styled(MenuList)(({theme})=>({
    width: "300px",
    padding: "0",
    margin: "0",
}))

const StyledMenuItem = styled(MenuItem)(({theme}) => ({
    textDecoration: "none",
    padding: ".5rem",
    height: "2.5rem"
})) 

const StyledMenuItemText = styled(Typography)(({theme}) => ({
    "&:hover": {
        transition: "all ease .2s",
        color: theme.palette.primary.main
    },
    textDecoration: "none",
    fontSize: "15px",
    fontFamily: "Inter",
    fontWeight: "400",
    color: theme.palette.text.main,
})) 

const StyledBottomLink = styled(Link)(({theme}) => ({
    color: "inherit", 
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    padding: "0 1rem",
    margin: ".5rem 0"
})) 

const StyledBottomLinkIcon = styled(Box)(({theme}) => ({
    fontSize: "24px"
})) 

