import { createSelector } from "@reduxjs/toolkit"
import { RootState } from "~/redux/_core/rootReducers";
import { NavbarState } from "~/redux/navbar/navbar.state";

const featureStateSelector = (state: RootState) => state.navbar;


export const navbarMenuVisibilitySelector = createSelector(
    featureStateSelector,
    (state: NavbarState) => state.menuVisibility
)
export const navbarMenuExapndedIndexSelector = createSelector(
    featureStateSelector,
    (state: NavbarState) => state.navbarMenuExapndedIndex
)

export const navbarPathSelector = createSelector(
    featureStateSelector,
    (state: NavbarState) => state.currentPath
)