import { useCallback } from "react";
import { 
navbarPathSelector,
navbarMenuVisibilitySelector,
navbarMenuExapndedIndexSelector
} from '~/redux/navbar/navbar.selector';
import {
    initNavbarStateAction,
    setNavbarPathAction, 
    setNavbarMenuVisibilityAction,
    setNavbarMenuExpandedIndexAction
} from '~/redux/navbar/navbar.actions';
import { useAppSelector, useAppDispatch } from '~/redux/_core/store';

export default function useNavbar(){
    const dispatch = useAppDispatch();
    const navbarMenuVisibility = useAppSelector(navbarMenuVisibilitySelector);
    const navbarMenuExapndedIndex = useAppSelector(navbarMenuExapndedIndexSelector);
    const navbarPath = useAppSelector(navbarPathSelector);

    const initState = useCallback(
        () => {
          dispatch(
            initNavbarStateAction()
          )
        },
        [dispatch]
      )
    const setNavbarPath = useCallback(
        (path: string) => {
          dispatch(
            setNavbarPathAction(path)
          )
        },
        [dispatch]
      )
    const setNavbarMenuVisibility = useCallback(
        (menuVisibility: boolean) => {
          dispatch(
            setNavbarMenuVisibilityAction(menuVisibility)
          )
        },
        [dispatch]
      )
    const setNavbarMenuExpandedIndex = useCallback(
        (index: number | null) => {
          dispatch(
            setNavbarMenuExpandedIndexAction(index)
          )
        },
        [dispatch]
      )

    return {
        navbarPath,
        navbarMenuVisibility,
        navbarMenuExapndedIndex,
        initState,
        setNavbarPath,
        setNavbarMenuVisibility,
        setNavbarMenuExpandedIndex
    }
}