//Hooks
import { useRouter } from 'next/router'


//MUI
import useMediaQuery from '@mui/material/useMediaQuery';
import styled from '@mui/system/styled';


//Compoenets
import Navbar from "~/components/_core/navbar/Navbar";
import NavbarV2 from "~/components/_core/navbar/NavbarV2";
import Footer from "~/components/_core/footer/Footer";

//Settings
const navbarHeight = "58px";


export default function Layout({children} : {children: React.ReactNode}){
    const router = useRouter();
    const mobile = useMediaQuery('(max-width:1250px)');
    

    return (
        <StyledLayout>
            <StyledNavbarWrapper>                   
                <NavbarV2/>            
            </StyledNavbarWrapper>
            
            <StyledPageContentWrapper>
                {children}
                <Footer/>
            </StyledPageContentWrapper>
        </StyledLayout>
    )
}

const StyledLayout = styled('div')(({theme}) => ({
    width: "100%",
    padding: 0,
    margin: 0,
    backgroundColor: theme.palette.background.default,
}))

const StyledNavbarWrapper = styled("div")(({theme}) => ({
    backgroundColor: theme.palette.background.default,
    height: `${navbarHeight}`,
    width: "100%",
}))
  
  const StyledPageContentWrapper = styled('div')(({theme}) => ({
    width: "100%",
    height: `calc(100vh - ${navbarHeight})`,
    overflow: "auto",
    backgroundColor: theme.palette.background.default,
}))