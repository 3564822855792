import React from 'react';
import Image from 'next/image';
import {env} from "~/env.mjs"

//Mui
import styled from '@mui/system/styled';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

//Imgs & Icons
import VortexLogo from "~/assets/img/vortex-air-logo-250.png";
import InstagramIcon from '@mui/icons-material/Instagram';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PlaceIcon from '@mui/icons-material/Place';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import EmailIcon from '@mui/icons-material/Email';
import ArticleIcon from '@mui/icons-material/Article';

//Docs
import AirAgencyCertificateBQNDoc from '~/assets/pdf/Vortex Satellite Air Agency Certificate BQN.pdf';
import AirAgencyCertificateSJUDoc from '~/assets/pdf/Vortex Air Agency Certificate SJU.pdf';

//Components
import FooterLink from './FooterLink';
import FooterIconLink from './FooterIconLink';

export default function Footer() {
    const baseUrl = env.NEXT_PUBLIC_APP_BASE_URL;
    
    return (
      <StyledFooter>
          <StyledHeaderSection>
              <StyledHeaderSectionItem>
                  <StyledLogoContainer>
                      <Image alt="vortex-logo" {...VortexLogo}/>
                  </StyledLogoContainer>
                  
              </StyledHeaderSectionItem>
              <StyledHeaderSectionItem>
                  <StyledHeaderSectionButtonGroup>
                      <StyledHeaderSectionButton variant="contained">
                          <Link href={`${baseUrl}/contact`} style={{textDecoration: "none", color: "inherit"}}>
                              <Typography variant="button">CONTACT US!</Typography>
                          </Link>     
                      </StyledHeaderSectionButton>
  
                      <StyledHeaderSectionButton variant="outlined">
                          <Link href="https://mbox.s501.sureserver.com/?domain=vortex-air.com&" style={{textDecoration: "none", color: "inherit"}}> 
                              <Typography variant="button">EMPLOYEE LOGIN</Typography>
                          </Link>
                      </StyledHeaderSectionButton>
                  </StyledHeaderSectionButtonGroup>
              </StyledHeaderSectionItem>
          </StyledHeaderSection>
  
          <StyledLinksSection>
              <StyledLinkGrid container rowSpacing={2} columnSpacing={10}>
                  <StyledLinkGridItem item xs={12} md={6} lg={3}>
                      <StyledLinkTitleContainer>
                          <Typography variant='h6' color="primary">Quick Links</Typography>
                      </StyledLinkTitleContainer>
                      <Divider/>
                      <StyledLinksContainer>
                          <StyledLinkItem>
                              <FooterLink
                              label='Home'
                              href={`${baseUrl}/home`}
                              />
                          </StyledLinkItem>
                          <StyledLinkItem>
                              <FooterLink
                              label='Our Story'
                              href={`${baseUrl}/about`}
                              />
                          </StyledLinkItem>
                          <StyledLinkItem>
                              <FooterLink
                              label='Certifications'
                              href={`${baseUrl}/certifications`}
                              />
                          </StyledLinkItem>  
                          <StyledLinkItem>
                              <FooterLink
                              label='Contact Us'
                              href={`${baseUrl}/contact`}
                              />
                          </StyledLinkItem>  
                          <StyledLinkItem>
                              <FooterLink
                              label='Careers'
                              href={`${baseUrl}/careers`}
                              />
                          </StyledLinkItem>  
                          <StyledLinkItem>
                              <FooterLink
                              label='Our Facilities'
                              href={`${baseUrl}/facility`}
                              />
                          </StyledLinkItem>  
                      </StyledLinksContainer>
                  </StyledLinkGridItem>
  
                  <StyledLinkGridItem item xs={12} md={6} lg={3}>
                      <StyledLinkTitleContainer>
                          <Typography variant='h6' color="primary">Aguadilla</Typography>
                      </StyledLinkTitleContainer>
                      <Divider/>
                      <StyledLinksContainer>
                          <StyledLinkItem className='iconLinkItem'>
                              <FooterLink
                              label='Rafael Hernandez Airport (BQN)'
                              href='https://goo.gl/maps/BgrgfhuLjz7tAify9'
                              icon={<PlaceIcon fontSize="inherit"/>}
                              />
                          </StyledLinkItem>
                          <StyledLinkItem className='iconLinkItem'>
                              <FooterLink
                              label='787-806-6017'
                              href='tel:787-806-6017'
                              icon={<LocalPhoneIcon fontSize="inherit"/>}
                              />
                          </StyledLinkItem> 
                          <StyledLinkItem>
                              <FooterLink
                              label='Visit Facility'
                              href={`${baseUrl}/facility/bqn`}
                              icon={<WarehouseIcon fontSize="inherit"/>}
                              />
                          </StyledLinkItem>   
                          <StyledLinkItem>
                              <FooterLink
                              label='Air Agency Certificate'
                              href={`${baseUrl}/${AirAgencyCertificateBQNDoc}`}
                              icon={<ArticleIcon fontSize="inherit"/>}
                              />
                          </StyledLinkItem>   
                      </StyledLinksContainer>
                  </StyledLinkGridItem>
  
                  <StyledLinkGridItem item xs={12} md={6} lg={3}>
                      <StyledLinkTitleContainer>
                          <Typography variant='h6' color="primary">San Juan</Typography>
                      </StyledLinkTitleContainer>
                      <Divider/>
                      <StyledLinksContainer>
                          <StyledLinkItem className='iconLinkItem'>
                              <FooterLink
                              label='Luis Muñoz Marin International Airport (SJU)'
                              href='https://goo.gl/maps/SnkX3vJuyhVCnCBu7'
                              icon={<PlaceIcon fontSize="inherit"/>}
                              />
                          </StyledLinkItem>
                          <StyledLinkItem className='iconLinkItem'>
                              <FooterLink
                              label='787-806-6017'
                              href='tel:787-806-6017'
                              icon={<LocalPhoneIcon fontSize="inherit"/>}
                              />
                          </StyledLinkItem>  
                          <StyledLinkItem>
                              <FooterLink
                              label='Visit Facility'
                              href={`${baseUrl}/facility/sju`}
                              icon={<WarehouseIcon fontSize="inherit"/>}
                              />
                          </StyledLinkItem>  
                          <StyledLinkItem>
                              <FooterLink
                              label='Air Agency Certificate'
                              href={`${baseUrl}/${AirAgencyCertificateSJUDoc}`}
                              icon={<ArticleIcon fontSize="inherit"/>}
                              />
                          </StyledLinkItem>  
                      </StyledLinksContainer>
                  </StyledLinkGridItem>
              </StyledLinkGrid>
          </StyledLinksSection>
  
          <StyledMediaIconSection>
              <StyledMediaIconItem>
                  <FooterIconLink href="https://www.instagram.com/vortex_aviation/" icon={<InstagramIcon fontSize='inherit'/>}/>
              </StyledMediaIconItem>
              <StyledMediaIconItem>
                  <FooterIconLink href="mailto:vortex.mx@usa.com" icon={<EmailIcon fontSize='inherit'/>}/>
              </StyledMediaIconItem>
          </StyledMediaIconSection>
  
          <StyledCopyrightSection>
              <StyledCopyrightItem>
                  <Typography variant='body2'>© Vortex Aviation Maintenance | All Rights Reserved</Typography>
              </StyledCopyrightItem>
          </StyledCopyrightSection>
      </StyledFooter>
    )
  }
  
  const StyledFooter = styled('div')(({theme}) => ({
      width: "100%",
      padding: "2rem 0",
      backgroundColor: theme.palette.background.paper,
      backgroundPositionY: "center",
  
      "@media (max-width: 900px)": {
          padding: "2rem 1rem",
      }
  }));
  
  // ----------------------------------------Header Section-------------------------------------------------------
  
  const StyledHeaderSection = styled('section')(({theme}) => ({
      width: "1400px",
      display: "flex",
      justifyContent: "space-between",
      margin: "auto",
      padding: "2rem 1rem",
  
      "@media (max-width: 1200px)": {
          width: "100%",
          padding: "1rem 1rem",
      },
      "@media (max-width: 900px)": {
          flexDirection: "column",
      }
  })); 
  
  const StyledHeaderSectionItem = styled('div')(({theme}) => ({
  
  })); 
  
  const StyledLogoContainer = styled('div')(({theme}) => ({
        display: "flex",

        "img":{
            borderRadius: "5px",
            display: "bloc",
            objectFit: "scale-down",
        
            "@media (max-width: 1200px)": {
                width: "auto",
                borderRadius: "2px",
            },
            "@media (max-width: 600px)": {
                borderRadius: "2px",
            },
        }
  })); 
  
  
  const StyledHeaderSectionButtonGroup = styled('div')(({theme}) => ({
      display: "flex",
      padding: "1rem 0",
      paddingTop: "0",
  
      "@media (max-width: 1200px)": {
          flexDirection: "column",
      },
      "@media (max-width: 900px)": {
          display: "flex",
          flexDirection: "initial", 
          padding: "1rem 0",  
      }
  })); 
  
  const StyledHeaderSectionButton = styled(Button)(({theme}) => ({
      margin: "0 .2rem",
      width: "180px",
  
      "@media (max-width: 1200px)": {
          margin: ".2rem 0rem",
      },
      "@media (max-width: 900px)": {
          margin: "0rem .2rem",
      }, 
  })); 
  
  const StyledHeaderSectionLink = styled(Link)(({theme}) => ({
      margin: "0 .2rem",
  })); 
  
  // ----------------------------------------Links Section-------------------------------------------------------
  
  const StyledLinksSection = styled('section')(({theme}) => ({
      width: "1400px",
      display: "flex",
      margin: "auto",
      padding: "2rem 1rem",
  
      "@media (max-width: 1200px)": {
          width: "100%",
      },
      "@media (max-width: 900px)": {
          
      }
  })); 
  
  const StyledLinkGrid = styled(Grid)(({theme}) => ({
  
      "@media (max-width: 1200px)": {
  
      },
      "@media (max-width: 900px)": {
          
      }
  })); 
  
  const StyledLinkGridItem = styled(Grid)(({theme}) => ({
  
      "@media (max-width: 1200px)": {
  
      },
      "@media (max-width: 900px)": {
          
      }
  })); 
  
  const StyledLinkTitleContainer = styled('div')(({theme}) => ({
      padding: ".4rem .5rem",
  
      "@media (max-width: 900px)": {
          padding: ".2rem .4rem",
      }
  })); 
  
  const StyledLinksContainer = styled('div')(({theme}) => ({
      padding: ".4rem .5rem",
      ".iconLinkItem": {
          padding: ".4rem 0",
          "@media (max-width: 1200px)": {
              padding: ".2rem 0",
          },
      },
  
      "@media (max-width: 900px)": {
          padding: ".2rem .4rem",
      }
  })); 
  
  const StyledLinkItem = styled('div')(({theme}) => ({
      padding: ".2rem 0",
  
      "@media (max-width: 1200px)": {
  
      },
      "@media (max-width: 900px)": {
          
      }
  })); 
  
  // ----------------------------------------Media Icon Section-------------------------------------------------------
  
  const StyledMediaIconSection = styled('section')(({theme}) => ({
      width: "1400px",
      display: "flex",
      margin: "auto",
      padding: "0rem 1rem",
  
      "@media (max-width: 1200px)": {
          width: "100%",
      },
      "@media (max-width: 900px)": {
          
      }
  })); 
  
  const StyledMediaIconItem = styled('div')(({theme}) => ({
      margin: "0 .4rem",
  })); 
  
  // ----------------------------------------Copyright Section-------------------------------------------------------
  
  const StyledCopyrightSection = styled('section')(({theme}) => ({
      width: "1400px",
      display: "flex",
      margin: "auto",
      padding: "2rem 1rem",
  
      "@media (max-width: 1200px)": {
          width: "100%",
      },
      "@media (max-width: 900px)": {
          
      }
  })); 
  
  const StyledCopyrightItem = styled('div')(({theme}) => ({
  
  })); 