import React, { useEffect } from "react";
import Link from 'next/link';
import { NavbarSubItemType } from "~/types/navbar/NavbarTypes";

//Hooks
import useNavbar from "~/hooks/redux/navbar/useNavbar";

//Mui
import styled from '@mui/system/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Collapse from '@mui/material/Collapse';

//Icons
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';



//Components
interface Props {
    label: string;
    index: number;
    subItems: NavbarSubItemType[];
}

export default function NavbarMenuSubItems(props: Props) {
    const {label, subItems, index} = props;
    const {setNavbarMenuVisibility, navbarMenuExapndedIndex, setNavbarMenuExpandedIndex} = useNavbar()
    //const [open, setOpen] = React.useState((navbarMenuExapndedIndex && navbarMenuExapndedIndex === index)? true : false);

    const open = (navbarMenuExapndedIndex && navbarMenuExapndedIndex === index)? true : false

    function handleCollapseClick(){
        if(open){
            setNavbarMenuExpandedIndex(null);
        } else{
            setNavbarMenuExpandedIndex(index);
        }
    }

    function handleSubItemClick(){
        setNavbarMenuExpandedIndex(null);
        setNavbarMenuVisibility(false);
    }

    function getSubItemLinkPath(index: number){
        const externalLink = subItems[index]?.externalLink;
        const to = subItems[index]?.to;

        return (externalLink)? externalLink : to;
    }

    useEffect(() => {

    }, [navbarMenuExapndedIndex, open])
    
    return(
        <Box key={`menu-sub-item-collapsable-${index}`}>
        <StyledListItemButton onClick={() => handleCollapseClick()}>
            <StyledListItemText primary={label}/>
            { open
            ? <ExpandLess/>
            : <ExpandMore/>
            }
        </StyledListItemButton>
        <Collapse in={open}>

            <StyledList>
                {subItems.map((item, i) => (
                    <Link href={getSubItemLinkPath(i)} style={{textDecoration: "none", color: "inherit"}} key={`nav-menu-sub-item-${i}`}>
                        <StyledMenuItem onClick={handleSubItemClick}>
                            <StyledMenuItemText
                            sx={{
                                color: "text.main",
                                "&:hover":{
                                    transition: "all ease .2s",
                                    color: "primary.main"
                                }
                            }}
                            >{item.label}</StyledMenuItemText>
                        </StyledMenuItem>
                    </Link>
                ))}
            </StyledList>
        </Collapse>
        </Box>
    )
}

const StyledList = styled(MenuList)(({theme})=>({
    width: "250px",
    padding: "0",
    margin: "0",
}))

const StyledListItemButton = styled(ListItemButton)(({theme})=>({
    padding: ".5rem",
    margin: "0",
    height: "2.5rem"
}))

const StyledListItemText = styled(ListItemText)(({theme})=>({
    ".MuiListItemText-primary": {
        fontSize: "15px"
    }
    
}))

const StyledMenuItem = styled(MenuItem)(({theme}) => ({
    textDecoration: "none",
    padding: ".5rem",
    height: "2.5rem"
})) 

const StyledMenuItemText = styled(Typography)(({theme}) => ({
    // "&:hover": {
    //     transition: "all ease .2s",
    //     color: theme.palette.primary.main
    // },
    textDecoration: "none",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: "400",
    //color: theme.palette.text.main,
    marginLeft: "1.2rem",
})) 


