import React from "react";
import { NavbarItemType } from "~/types/navbar/NavbarTypes";

//Hooks


//Mui
import styled from '@mui/system/styled';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';


//Icons


//Components
import NavbarButton from "./NavbarButton";

interface Props {
    items: NavbarItemType[];
}

export default function NavbarButtonTabs(props: Props) {

    const { items } = props;
    const lastItemIndex = items.length - 1;
    

    return(
        <StyledNavbarButtonsContainer>
            {items.map((item, index) => (
                <ButtonWrapper key={`navbar-button-wrapper-${index}`}>
                    <Box sx={{display: "flex"}}>
                        <NavbarButton item={item} key={`navbar-button-${index}`}/>
                        {(lastItemIndex !== items.length) && 
                            <Box>
                                <Divider orientation="vertical"/>
                            </Box>   
                        }
      
                    </Box>  
                </ButtonWrapper>
               
            ))}
        </StyledNavbarButtonsContainer>
    )
}

const StyledNavbarButtonsContainer = styled(Box)(({theme}) => ({
    display: "flex",
}))

const ButtonWrapper = styled(Box)(({theme}) => ({


}))