import React from "react";
import { NavbarSubItemType } from "~/types/navbar/NavbarTypes";
import Link from 'next/link';

//Hooks


//Mui
import styled from '@mui/system/styled';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
//Icons


//Components


interface Props {
    subItems: NavbarSubItemType[];
    anchorEl: null | HTMLElement;
}

export default function NavbarSubItemsPaper(props: Props) {

    const { subItems, anchorEl } = props;
    const open = Boolean(anchorEl);
    const id = open ? 'fade-popper' : undefined;

    function getSubItemLinkPath(index: number){
        const externalLink: string | undefined = subItems[index]?.externalLink as string | undefined;
        const to = subItems[index]?.to;

        return (externalLink)
        ? externalLink 
        : to 
            ? to 
            : "";
    }


    return(
        <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
        sx={{zIndex: "1000"}}
        >
      {({ TransitionProps }) => ( 
        <Fade  {...TransitionProps} timeout={450}>
            <StyledPaper elevation={4}>
                <StyledMenuList>
                    {subItems.map((subItem, index) => (
                        <Link 
                        key={`subItems-menu-item-${index}`}
                        href={getSubItemLinkPath(index)} 
                        passHref 
                        style={{textDecoration: "none", color: "inherit"}}
                        >
                            <StyledMenuItem
                            sx={{
                                color: "text.main",
                                "&:hover": {
                                    transition: "all ease .2s",
                                    color: "primary.main"
                                }
                            }}
                            >{subItem.label}</StyledMenuItem>
                        </Link>
                    
                    ))}
                </StyledMenuList>
            </StyledPaper>
       </Fade>
      )}
        </Popper>
    )
}

const StyledPaper = styled(Paper)(({theme}) => ({
    display: "flex",
    width: "250px",
    marginTop: "1rem",
    zIndex: "2000",
}))

const StyledMenuList = styled(MenuList)(({theme}) => ({
    width: "100%"
})) 

const StyledMenuItem = styled(MenuItem)(({theme}) => ({
    fontSize: "15px",
    fontFamily: "Inter",
    fontWeight: "400",
})) 