import React, {useEffect, useState} from 'react';
import { motion, useSpring, useAnimation } from "framer-motion";

//Mui
import styled from '@mui/system/styled';
import useTheme from '@mui/system/useTheme';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

interface FooterLinkProps{
    href: string;
    label: string;
    icon?: React.ReactNode;
}

export default function FooterLink(props: FooterLinkProps) {
    const [hovered, setHovered] = useState(false);
    const controls = useAnimation();
    const theme = useTheme();

    useEffect(() => {

        if(hovered){
            controls.start('hovered');
        } else {
            controls.start('notHovered');
        }

    }, [hovered])
  return (
    <StyledLink
    href={props.href}
    onMouseEnter={() => setHovered(true)}
    onMouseLeave={() => setHovered(false)}
    animate={controls}
    >   
        { props.icon && 
                <StyledIcon
                animate={controls}
                initial="notHovered"
                transition={{ duration: .5}}     
                variants={{
                    hovered: {
                        color: theme.palette.primary.main,
                    },
                    notHovered: {
                        
                        color: theme.palette.primary.main,    
                    }
                }}
                >
                    {props.icon}
                </StyledIcon>
        }
        <StyledLabel
            animate={controls}
            initial="notHovered"
            transition={{ duration: 5, type: "spring", stiffness: 150, damping: 17 }}
            variants={{
                hovered: {
                    color: theme.palette.primary.main,
                    x: 4
                },
                notHovered: {
                    color: theme.palette.text.primary,
                    x: 0
                }
            }} 
        >
            {props.label}
        </StyledLabel>

    </StyledLink>
  )
}

const StyledLink = styled(motion.a)(({theme}) => ({
    display: "inline-flex",
    width:"auto !important",
    alignItems: "center",
    justifyContent: "start",
    textDecoration: "none",
    padding: "0",
    margin: "0",
    height: "inherit",
    color: theme.palette.text.primary,

    ":hover": {
        cursor: "pointer"
    }
}));

const StyledLabel = styled(motion.p)(({theme}) => ({
    fontSize: "14px",
    fontWeight: "600",
    fontFamily: "Inter",
    margin: "0",
}));

const StyledIcon = styled(motion.div)(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "22px",
    marginRight: ".5rem"
}));