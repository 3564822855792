import React, {useEffect, useState} from "react";
import Link from 'next/link';
import { NavbarItemType } from "~/types/navbar/NavbarTypes";
import {motion, Variants, useAnimation} from "framer-motion"

//Hooks
import { useRouter } from "next/router";

//Mui
import styled from '@mui/system/styled';
import {useTheme} from "@mui/material/styles";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

//Icons
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

//Components
import NavbarSubItemsPaper from "./NavbarSubItemsPaper";


interface Props {
    item: NavbarItemType
}

export default function NavbarButton(props: Props) {
    const { item } = props;
    const theme = useTheme();
    const router = useRouter();
    const animation = useAnimation();
    const [isSelected, setIsSelected] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);


    /**
    * Styles Variants
    */
    const buttonVariants: Variants = {
        initial: { 
            opacity: 1, 
            color: theme.palette.text.primary,
            transition: {duration: 0}
        },
        hover: { opacity: 1, 
            color: theme.palette.primary.main,
            cursor: "pointer",
            transition: {duration: 0},
            borderBottom: `2px solid ${theme.palette.primary.main}`
        },
        selected: { 
            opacity: 1,  
            color: theme.palette.primary.main,
            transition: {duration: 0},
            borderBottom: `2px solid ${theme.palette.primary.main}`
        },
    }
    

    const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setIsHovered(true)
    };

    const handleMouseLeave = () => {
        setAnchorEl(null);
        setIsHovered(false)
    };

    function isSelectedPath(){       
        const currentPath = router.pathname;

        if(currentPath === "/"){
            if(item.label.toLocaleLowerCase() === "home"){
                return true;
            }
        }

        if(item.to.includes(currentPath) && currentPath !== "/"){
            return true;
        }

        if(currentPath !== "/" && item.subItems && item.subItems.find((subItem) => subItem.to.includes(currentPath))){
            return true;
        }
        
        return false;
        
    }

    useEffect(() => {

        (isSelectedPath())? setIsSelected(true) : setIsSelected(false);
        
    }, [isSelected, isHovered])

    useEffect(() => {

        if(isSelected && !isHovered){
            animation.start("selected");   
        } 
        else {
            animation.start("initial")
        }
        
    }, [isSelected, isHovered])




    return(
        <Link href={item.to} style={{textDecoration: "none", height: "45px"}}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        >
            <StyledButton  
            animate={animation} 
            variants={buttonVariants}
            initial="initial"
            whileHover="hover"
            transition={{duration: .1}}
            >
                <Box>
                    <StyledButtonLabel variant="button">{item.label}</StyledButtonLabel>
                </Box>
                {item.subItems && 
                <Box>
                    <KeyboardArrowDownRoundedIcon sx={{fontSize: "24px"}}/>
                </Box>
                }

               
                
            </StyledButton>

            {item.subItems && 
                <NavbarSubItemsPaper subItems={item.subItems} anchorEl={anchorEl}/>
            }
        </Link>

    )
}

const StyledButton = styled(motion.button)(({theme}) => ({
    display: "flex",
    textDecoration: "none",
    backgroundColor: "transparent",
    border: "none",
    padding: ".4rem .6rem",
    borderRadius: "0",
    margin: "0 .4rem",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    boxSizing: "border-box"
}))

const StyledButtonLabel = styled(Typography)(({theme}) => ({
    fontSize: "16px",
    fontFamily: "Julius Sans One",
    fontWeight: "600",
    textTransform: "uppercase",    
}))

