import { NavbarItemType, NavbarData } from '~/types/navbar/NavbarTypes';
import {env} from "~/env.mjs"

import AirAgencyCertificateBQNDoc from '~/assets/pdf/Vortex Satellite Air Agency Certificate BQN.pdf';
import AirAgencyCertificateSJUDoc from '~/assets/pdf/Vortex Air Agency Certificate SJU.pdf';
import A449Doc from "~/assets/pdf/A449.pdf";
import EASACertificateDoc from "~/assets/pdf/EASA Certificate.pdf"; 
import OpsSpecDoc from '~/assets/pdf/OPSPECSD107.pdf';

const baseUrl = env.NEXT_PUBLIC_APP_BASE_URL


export const navbarData: NavbarData = {
    items: [
        {
            label: "Home",
            to: `${baseUrl}/home`
        },
        {
            label: "About",
            to: `${baseUrl}/about`,
        },
        {
            label: "Certifications",
            to: `${baseUrl}/certifications`,
            subItems: [
                {
                    label: "Overview",
                    to: `${baseUrl}/certifications`,
                },
                {
                    label: "Air Agency Certificate SJU",
                    to:"/certifications",
                    externalLink: `${baseUrl}/${AirAgencyCertificateSJUDoc}`
                },
                {
                    label: "Air Agency Certificate BQN",
                    to:"/certifications",
                    externalLink: `${baseUrl}/${AirAgencyCertificateBQNDoc}`
                },
                {
                    label: "EASA Certificate",
                    to:"/certifications",
                    externalLink: `${baseUrl}/${EASACertificateDoc}`,
                },
                {
                    label: "Ops Spec",
                    to:"/certifications",
                    externalLink:`${baseUrl}/${OpsSpecDoc}` 
                },
                {
                    label: "A449",
                    to:"/certifications",
                    externalLink: `${baseUrl}/${A449Doc}`
                },
            ]
        },
        {
            label: "Clients",
            to: `${baseUrl}/clients`,
        },
        {
            label: "Facilities",
            to: `${baseUrl}/facility`,
            subItems: [
                {
                    label: "Overview",
                    to: `${baseUrl}/facility`,
                },
                {
                    label: "SJU Repair Station",
                    to: `${baseUrl}/facility/sju`,
                },
                {
                    label: "BQN Repair Station",
                    to: `${baseUrl}/facility/bqn`,
                },
            ]
        },
        {
            label: "Contact",
            to: `${baseUrl}/contact`,
        },
    ]
}
